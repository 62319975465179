var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "header-required" }, [_vm._v("Contributors")]),
      _c("page-snippet", { attrs: { name: "contributors" } }),
      _c("b-table", {
        ref: "contributorTable",
        attrs: {
          small: "",
          fields: _vm.contributorTableFields,
          items: _vm.model.Contributors
        },
        scopedSlots: _vm._u([
          {
            key: "cell(DisplayPriority)",
            fn: function(data) {
              return [
                _vm._v(" " + _vm._s(100 - data.item.DisplayPriority) + " ")
              ]
            }
          },
          {
            key: "cell(Name)",
            fn: function(data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(data.item.NamePart1) +
                    " " +
                    _vm._s(data.item.NamePart2) +
                    " " +
                    _vm._s(data.item.NamePart3) +
                    " "
                )
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(data) {
              return [
                _c("b-icon-pencil-square", {
                  on: {
                    click: function($event) {
                      return _vm.editItem(data)
                    }
                  }
                }),
                _c("b-icon-x", {
                  on: {
                    click: function($event) {
                      return _vm.deleteItem(data)
                    }
                  }
                }),
                _vm.copyToTracks
                  ? _c("b-icon-chevron-double-right", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      attrs: { title: "Copy to all tracks" },
                      on: {
                        click: function($event) {
                          return _vm.$emit(
                            "copyToTracks",
                            "Contributor",
                            null,
                            Object.assign({}, data.item)
                          )
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _vm.editingContributor
        ? _c(
            "b-modal",
            {
              attrs: {
                "no-close-on-esc": "",
                "no-close-on-backdrop": "",
                "hide-header-close": "",
                scrollable: "",
                size: "xl",
                id: "edit-contributor",
                title: "Contributor",
                "hide-footer": ""
              },
              model: {
                value: _vm.showEditor,
                callback: function($$v) {
                  _vm.showEditor = $$v
                },
                expression: "showEditor"
              }
            },
            [
              _c(
                "b-form-group",
                {
                  class: { required: true, "mt-4": true },
                  attrs: {
                    id: "nameType-group",
                    label: "Name type",
                    "label-for": "nameType-select"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "nameType-select",
                      options: _vm.nameTypeOptions
                    },
                    model: {
                      value: _vm.editingContributor.NameType,
                      callback: function($$v) {
                        _vm.$set(_vm.editingContributor, "NameType", $$v)
                      },
                      expression: "editingContributor.NameType"
                    }
                  })
                ],
                1
              ),
              _vm.editingContributor.NameType == "person"
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        {
                          class: { "mt-4": true },
                          attrs: {
                            id: "name1-group",
                            label: "First name",
                            "label-for": "part1-input"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "part1-input",
                            attrs: { id: "part1-input", type: "text" },
                            model: {
                              value: _vm.editingContributor.NamePart1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editingContributor,
                                  "NamePart1",
                                  $$v
                                )
                              },
                              expression: "editingContributor.NamePart1"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          class: { "mt-4": true },
                          attrs: {
                            id: "name3-group",
                            label: "Last name",
                            "label-for": "part3-input"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "part3-input",
                            attrs: { id: "part3-input", type: "text" },
                            model: {
                              value: _vm.editingContributor.NamePart3,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editingContributor,
                                  "NamePart3",
                                  $$v
                                )
                              },
                              expression: "editingContributor.NamePart3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editingContributor.NameType == "entity"
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        {
                          class: { "mt-4": true },
                          attrs: {
                            id: "name3-group",
                            label: "Name",
                            "label-for": "part3-input"
                          }
                        },
                        [
                          _c("b-form-input", {
                            ref: "part3-input",
                            attrs: { id: "part3-input", type: "text" },
                            model: {
                              value: _vm.editingContributor.NamePart3,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editingContributor,
                                  "NamePart3",
                                  $$v
                                )
                              },
                              expression: "editingContributor.NamePart3"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-checkbox",
                {
                  attrs: { id: "contributor-name-auto-formatting" },
                  model: {
                    value: _vm.editingContributor.AutoFormatting,
                    callback: function($$v) {
                      _vm.$set(_vm.editingContributor, "AutoFormatting", $$v)
                    },
                    expression: "editingContributor.AutoFormatting"
                  }
                },
                [_vm._v(" Automatically apply standard casing / styling ")]
              ),
              _c("field", {
                attrs: {
                  defs: _vm.defs,
                  name: "Contributors.Role",
                  model: _vm.editingContributor
                }
              }),
              _c("field", {
                attrs: {
                  defs: _vm.defs,
                  name: "Contributors.DisplayPriority",
                  model: _vm.editingContributor
                }
              }),
              _c("field", {
                attrs: {
                  defs: _vm.defs,
                  name: "Contributors.Country",
                  model: _vm.editingContributor
                }
              }),
              _vm._l(_vm.warnings, function(warning, index) {
                return _c("b-alert", {
                  key: index,
                  attrs: { show: "", variant: "warning" },
                  domProps: { innerHTML: _vm._s(warning) }
                })
              }),
              _vm._l(_vm.errors, function(error, index) {
                return _c(
                  "b-alert",
                  { key: index, attrs: { show: "", variant: "danger" } },
                  [_vm._v(" " + _vm._s(error) + " ")]
                )
              }),
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      _vm.errors = []
                      _vm.editingIndex = null
                      _vm.editingContributor = null
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-3",
                  attrs: {
                    variant: "primary",
                    disabled: _vm.fullName == "" || !_vm.editingRole
                  },
                  on: { click: _vm.onEditOk }
                },
                [
                  _vm.fullName == "" || !_vm.editingRole
                    ? [_vm._v(" Add ")]
                    : _vm._e(),
                  _vm.fullName != "" && _vm.editingRole
                    ? [
                        _vm._v(
                          " Add '" +
                            _vm._s(_vm.fullName) +
                            "' as " +
                            _vm._s(_vm.editingRole) +
                            " "
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            2
          )
        : _vm._e(),
      _c("b-button", { on: { click: _vm.add } }, [_vm._v("Add Contributor")]),
      _vm.type == "album"
        ? _c(
            "b-button",
            { staticClass: "ml-4", on: { click: _vm.copyFromTracks } },
            [_vm._v("Copy contributors from tracks")]
          )
        : _vm._e(),
      _vm._l(_vm.fieldErrors, function(error, index) {
        return _c("b-alert", {
          key: index,
          staticClass: "mt-4",
          attrs: { show: _vm.showValidationErrors.value, variant: "danger" },
          domProps: { innerHTML: _vm._s(error) }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }